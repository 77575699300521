import clsx from "clsx"
import type { ReactNode } from "react"
import React, { useState } from "react"
import type { CardProps } from "../card/Card"
import { Card } from "../card/Card"
import PlusIcon from "../../images/plus_icon.svg"
import "./benefits.scss"

export const BenefitsCard: React.FC<
  CardProps & { title: string; description: ReactNode }
> = ({ title, description, trim }) => {
  const [open, setOpen] = useState(true)
  const toggleCard = () => {
    setOpen(!open)
  }
  return (
    <Card className="benefits-card" trim={trim}>
      <div className="card-wrapper">
        <div className="title-2">{title}</div>
        <div className="description">{description}</div>
      </div>
    </Card>
  )
}

export const BenefitsNotes = () => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div>
      <p className="note hidden sm:block">
        *Potential venture funding upon completion of the program is subject to
        an individual valuation; completing the program does not guarantee a
        term sheet/offer to invest for equity.
      </p>
      <div className="note block sm:hidden">
        <p>
          <span>
            *Potential venture funding upon completion of the program is subject
            to an individual valuation; completing
          </span>
          {!showMore && <span>...</span>}
          {showMore && (
            <span>
              the program does not guarantee a term sheet/offer to invest for
              equity.
            </span>
          )}
          <button
            className="text-blue-purple-50"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <div className="next">
                <div className="flex">
                  <span className="text text-bold">&nbsp;Less</span>
                  <span className="toggle-less" />
                </div>
              </div>
            ) : (
              <div className="next">
                <div className="flex">
                  <span className="text text-bold">&nbsp;More</span>
                  <span className="toggle" />
                </div>
              </div>
            )}
          </button>
        </p>
      </div>
    </div>
  )
}
