import React from "react"
import { UniversalLink } from "../UniversalLink"
import "./partner.scss"

interface PartnerProps {
  name: string
  description: string
  website: string
}

export const Partner: React.FC<PartnerProps> = ({
  name,
  description,
  website,
}) => (
  <div className="partner">
    <div className={`${name.toLowerCase()}-logo`} />
    <p className="desc">{description}</p>
    <UniversalLink to={website} className="text-blue-purple-50 partner-link">
      Visit {name}
    </UniversalLink>
  </div>
)
