import React from "react"
import "./process.scss"

interface ProcessProps {
  title: string
  description: string
}

export const Process: React.FC<ProcessProps> = ({ title, description }) => (
  <div className="process grid grid-cols-9 sm:grid-cols-11 md:block">
    <div className="process-trim col-span-1 md:row-span-1" />
    <div className="process-text text-left col-span-8 sm:col-span-10 md:row-span-1 md:text-center">
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  </div>
)
