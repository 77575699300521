import clsx from "clsx"
import React, { useState } from "react"
import type { CardProps } from "../card/Card"
import { Card } from "../card/Card"
import PlusIcon from "../../images/plus_icon.svg"
import MinusIcon from "../../images/minus_icon.svg"
import "./program.scss"
import { UniversalLink } from "../UniversalLink"

interface ProgramCardProps extends CardProps {
  title: string
  description: string
  url?: string
  message?: string
  urlName?: string
  descriptionItems: string[]
}

export const ProgramCard: React.FC<ProgramCardProps> = ({
  title,
  description,
  trim,
  url,
  message,
  urlName,
  descriptionItems,
}) => {
  const [open, setOpen] = useState(false)
  const toggleCard = () => {
    setOpen(!open)
  }

  return (
    <Card className="programs-card" trim={trim}>
      <div className="card-wrapper ">
        <div className="title-1">{title}</div>
        <div className="program-details">
          <div className="program-description">
            <button className="program-detail-toggle" onClick={toggleCard}>
              <img
                className="program-image"
                src={open ? MinusIcon : PlusIcon}
                alt={`${open ? `minus` : `plus`}-icon`}
              />
              <p>{description}</p>
            </button>
          </div>
          {open && (
            <div>
              <ul className="description-list">
                {descriptionItems.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          {url && (
            <UniversalLink
              className="program-btn btn btn-primary btn-fixed btn-alignment"
              to={url}
            >
              <span>{urlName}</span>
            </UniversalLink>
          )}
          {message && <p className="program-message">{message}</p>}
        </div>
      </div>
    </Card>
  )
}
